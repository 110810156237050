
































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

interface dataBanner {
  id: number;
  imagen: string;
  imagen_mobile: string;
  link: string;
}

@Component({
  components: {},
})
export default class Home extends Vue {
  private win = window;
  private slide = 0;
  private horizontalPercentageInteres = 0;
  private aBanners: Array<dataBanner> = [];
  private noticias: Array<any> = [];
  private bannerSlide = 0;
  private linksAnuncios: any = [];
  private linksServicios: any = [];
  private galeria: Array<any> = [];
  private popUp: any = null;
  private verPopUp = false;
  private urlVideo: any = null;
  private selectedCategoria: any = null;
  private verModal: boolean = false;
  private slideIndex: number = 1;

  private mounted() {
    // Agrega nuevamente el script de twitter para que se muestre el plugin
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://platform.twitter.com/widgets.js"
    );
    document.head.appendChild(recaptchaScript);

    // // Agrega nuevamente el script de facebook para que se muestre el plugin
    // let recaptchaScriptFacebook = document.createElement("script");
    // recaptchaScriptFacebook.setAttribute(
    //   "src",
    //   "https://connect.facebook.net/es_ES/sdk.js#xfbml=1&version=v11.0&appId=843539176268307&autoLogAppEvents=1"
    // );
    // recaptchaScriptFacebook.setAttribute("crossorigin", "anonymous");
    // recaptchaScriptFacebook.setAttribute("nonce", "Lb0ZeKOd");
    // document.head.appendChild(recaptchaScriptFacebook);

    // Agrega nuevamente el script de instagram para que se muestre el plugin
    let recaptchaScriptInsta = document.createElement("script");
    recaptchaScriptInsta.setAttribute(
      "src",
      "https://cdn.lightwidget.com/widgets/lightwidget.js"
    );
    document.head.appendChild(recaptchaScriptInsta);

    this.getBanners();
    this.getNoticias();
    this.autoScroll();
    this.getLinksAnuncios();
    this.getPopUp();
    this.getVideo();
    this.autoscrollBanner();
  }
  private updated() {
    this.showSlides(this.slideIndex);
  }
  private getBanners() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("slider")
      .then((res) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.aBanners = res.data;
        for (let index = 0; index < this.aBanners.length; index++) {
          this.aBanners[index].imagen = base + this.aBanners[index].imagen;
          if (
            this.aBanners[index].imagen_mobile &&
            this.aBanners[index].imagen_mobile != ""
          ) {
            this.aBanners[index].imagen_mobile =
              base + this.aBanners[index].imagen_mobile;
          } else {
            this.aBanners[index].imagen_mobile = this.aBanners[index].imagen;
          }
          if (!this.aBanners[index].link) {
            this.aBanners[index].link = "#";
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.aBanners = [];
      });
  }

  private getNoticias() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("noticias/destacadas")
      .then((res) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.noticias = res.data;
        for (let i = 0; i < this.noticias.length; i++) {
          if (
            this.noticias[i].fotos.image_intro &&
            this.noticias[i].fotos.image_intro != ""
          ) {
            this.noticias[i].fotos.image_intro =
              base + this.noticias[i].fotos.image_intro;
          } else {
            this.noticias[i].fotos.image_intro =
              base + "Noticias/default_preview.jpg";
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private getLinksAnuncios() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("anuncios")
      .then((res: any) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.linksAnuncios = res.data.anuncios;
        this.linksServicios = res.data.servicios;
        for (let i = 0; i < this.linksServicios.length; i++) {
          this.linksServicios[i].foto = base + this.linksServicios[i].foto;
          for (let j = 0; j < this.linksServicios[i].servicios.length; j++) {
            this.linksServicios[i].servicios[j].foto =
              base + this.linksServicios[i].servicios[j].foto;
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private getPopUp() {
    this.$axios
      .get("popup")
      .then((res) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.popUp = res.data;
        if (this.popUp.imagen != "") {
          this.popUp.imagen = base + this.popUp.imagen;
        } else {
          this.popUp = null;
          this.verPopUp = false;
          return;
        }
        this.verPopUp = true;
      })
      .catch((err: any) => {
        this.popUp = null;
        this.verPopUp = false;
      });
  }

  private clickPopUp() {
    if (this.popUp && this.popUp.url && this.popUp.url != "") {
      window.open(this.popUp.url, "_self");
    }
  }

  private getVideo() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("home/video")
      .then((res: any) => {
        if (res.data && res.data != "") {
          this.urlVideo = res.data;
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.urlVideo = null;
        this.$q.loading.hide();
      });
  }

  private scrollAreaFunctionInteres(info: any) {
    this.horizontalPercentageInteres = info.horizontalPercentage;
  }

  private scroll(direccion: string) {
    if (this.$refs.scrollInteres as any) {
      const size = (this.$refs.scrollInteres as any).scrollSize;
      const position = (this.$refs.scrollInteres as any).scrollPosition;

      if (direccion == "derecha") {
        if (this.horizontalPercentageInteres >= 1) {
          (this.$refs.scrollInteres as any).setScrollPosition(0, 120);
        } else {
          (this.$refs.scrollInteres as any).setScrollPosition(
            position + 115,
            120
          );
        }
      } else {
        if (position - 115 < 0) {
          if (position > 0) {
            (this.$refs.scrollInteres as any).setScrollPosition(0, 120);
          } else {
            (this.$refs.scrollInteres as any).setScrollPosition(size, 120);
          }
        } else {
          (this.$refs.scrollInteres as any).setScrollPosition(
            position - 115,
            120
          );
        }
      }
    }
  }
  private abrirModal(item: any) {
    this.selectedCategoria = item;
    this.verModal = true;
  }
  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }

  private irNoticia(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Publicacion" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Publicacion",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private irCategoria(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Categoria" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Categoria",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "enero";
    } else if (mesInicio == 2) {
      mesInicio = "febrero";
    } else if (mesInicio == 3) {
      mesInicio = "marzo";
    } else if (mesInicio == 4) {
      mesInicio = "abril";
    } else if (mesInicio == 5) {
      mesInicio = "mayo";
    } else if (mesInicio == 6) {
      mesInicio = "junio";
    } else if (mesInicio == 7) {
      mesInicio = "julio";
    } else if (mesInicio == 8) {
      mesInicio = "agosto";
    } else if (mesInicio == 9) {
      mesInicio = "septiembre";
    } else if (mesInicio == 10) {
      mesInicio = "octubre";
    } else if (mesInicio == 11) {
      mesInicio = "noviembre";
    } else {
      mesInicio = "diciembre";
    }

    if (mesInicio != 0) {
      return diaInicio + " de " + mesInicio + " " + anhoInicio;
    }
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch((err) => {
          console.log(err);
        });
      }
    }
  }
  private routerGoServicios(name: string) {
    if (name && name != "") {
      this.$router
        .push({ name: "Servicios", query: { option: name } })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  private routerGoMenu(name: string, nombre_web: string) {
    if (nombre_web && nombre_web != "") {
      if (
        this.$router.currentRoute.name == name &&
        this.$router.currentRoute.params.nombre &&
        this.$router.currentRoute.params.nombre == nombre_web
      ) {
        this.$router.go(0);
      } else {
        if (this.$router.currentRoute.name == name) {
          if (
            this.$router.currentRoute.params.nombre &&
            this.$router.currentRoute.params.nombre != nombre_web
          ) {
            this.$router
              .push({ name: name, params: { nombre: nombre_web } })
              .catch((err) => {
                console.log(err);
              });
            this.$router.go(0);
          } else {
            this.$router
              .push({ name: name, params: { nombre: nombre_web } })
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          this.$router
            .push({ name: name, params: { nombre: nombre_web } })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  }

  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
    } else if (nombreWeb == "concejo-municipal" || nombreWeb == "concejo") {
      this.routerGo("Concejo");
    } else if (
      nombreWeb == "actas-del-concejo" ||
      nombreWeb == "actas-del-concejo-municipal"
    ) {
      this.routerGo("ActasConcejo");
    } else if (
      nombreWeb == "oficinas-y-telefonos-municipales" ||
      nombreWeb == "contactos"
    ) {
      this.routerGo("Telefonos");
    } else if (
      nombreWeb == "organigrama-municipal" ||
      nombreWeb == "organigrama"
    ) {
      this.routerGo("Organigrama");
    } else if (nombreWeb == "cosoc") {
      this.routerGo("Cosoc");
    } else if (nombreWeb == "informes-financieros") {
      this.routerGo("Informes");
    } else if (nombreWeb == "ordenanzas-municipales") {
      this.routerGo("Ordenanzas");
    } else if (nombreWeb == "ofertas-laborales") {
      this.routerGo("OfertasOmil");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch((err) => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch((err) => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      }
    }
  }
  private autoScroll() {
    this.scroll("derecha");
    setTimeout(this.autoScroll, 4000);
  }

  // Funciones del banner
  // Next/previous controls
  private plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  // Thumbnail image controls
  private currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }

  private showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("dot");
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    if (slides.length > 0) {
      for (i = 0; i < slides.length; i++) {
        (slides[i] as any).style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }

      (slides[this.slideIndex - 1] as any).style.display = "block";
      dots[this.slideIndex - 1].className += " active";
    }
  }

  private autoscrollBanner() {
    this.plusSlides(1);
    setTimeout(this.autoscrollBanner, 5000);
  }
  // Fin Funciones del banner
}
